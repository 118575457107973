import React, { useState } from 'react';
import css from './CopyToClipBoardButton.module.css';
import Button from '../Button/Button';

const CopyToClipBoardButton = (props) => {
  const { text = "", label } = props;
  const [tooltipText, setTooltipText] = useState("Copy to clipboard");

  const onCopyTextFn = () => {
    if (typeof window !== "undefined") {
      navigator.clipboard.writeText(text);
      setTooltipText("Copied");

      // Reset tooltip text after 2 seconds
      setTimeout(() => {
        setTooltipText("Copy to clipboard");
      }, 2000);
    }
  };

  return (
    <div className={css.tooltip}>
      <Button onClick={onCopyTextFn}>
        <span className={css.tooltiptext}>{tooltipText}</span>
        {label || "Copy text"}
      </Button>
    </div>
  );
};

export default CopyToClipBoardButton;
