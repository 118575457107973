import { queryUserListings, showUser } from "../ProfilePage/ProfilePage.duck";
import { types as sdkTypes } from '../../util/sdkLoader';
const { UUID } = sdkTypes;

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  const userId = new UUID(params.id);
  return Promise.all([
    dispatch(queryUserListings(userId, config)),
    dispatch(showUser(userId, config)),
  ]);
};
