import mapboxgl from 'mapbox-gl';
import { types as sdkTypes } from './sdkLoader';
import moment from 'moment';

const { LatLng, LatLngBounds } = sdkTypes;

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

/**
 * Checks if an array is valid (not null, undefined, or empty).
 * @param {array} arr - The array to check.
 * @returns {boolean} True if the array is valid, false otherwise.
 */
export const isArrayLength = arr => {
  // Check if the input parameter is an array and has a length greater than zero.
  return Array.isArray(arr) && (arr.length > 0 ?? false);
};

export const safeFind = (array, predicate) => array?.find(predicate) ?? null;

// Return captilized string
export const capitalizeFirstLetter = str => {
  if (typeof str !== 'string') {
    return '';
  }
  const string = str && str.toString();
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : null;
};

//This is a sharetribe response import with data.data
export const getPublicDataAttributeValue = (attributeName, data) => {
  if (data && data.attributes && data.attributes.publicData) {
    const publicData = data.attributes.publicData;
    if (publicData.hasOwnProperty(attributeName)) {
      return publicData[attributeName];
    }
  }
  return '';
};

export const getProductImages = images => {
  if (!Array.isArray(images)) {
    return [];
  }
  return images
    .map(
      item =>
        item?.attributes?.variants &&
        (item?.attributes?.variants['scaled-medium']?.url ||
          item?.attributes?.variants['listing-card-2x']?.url)
    )
    .filter(Boolean);
};

/**
 * Returns an array of wishlist items associated with a user's profile.
 * @param {object} currentUser - The user object.
 * @returns {array} An array of wishlist items, or an empty array if input is invalid or wishlist is missing.
 */
export const getWishlist = currentUser => {
  return (
    (currentUser && currentUser.id && currentUser?.attributes?.profile?.publicData?.wishlist) || []
  );
};

export const copyToClipboard = async text => {
  try {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(text);
      return;
    }

    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus({
      preventScroll: true,
    });
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
  } catch (e) {
    console.error(`Copying failed with error: ${e}`);
    throw e;
  }
};

export const appendAverageReviews = reviews => {
  const MIN_RATING = 1;
  const MAX_RATING = 5;

  const filteredReviews =
    Array.isArray(reviews) &&
    reviews.filter(r => r?.attributes?.rating !== null && r?.attributes?.state === 'public');

  const totalReviews = filteredReviews?.length || 0;

  if (!totalReviews) {
    return 0.0;
  }

  const ratings = filteredReviews.map(review => review?.attributes?.rating);
  const validRatings = ratings.filter(r => r >= MIN_RATING && r <= MAX_RATING);

  if (!validRatings.length) {
    return 0.0;
  }

  const starSum = validRatings.reduce((partialSum, rating) => partialSum + rating, 0);
  const averageRating = starSum / validRatings.length;

  return averageRating || 0;
};

export const reverseTradingCardObjects = tradingCardObjects => {
  const tradingCardData = {};

  // Iterate through each object in the tradingCardObjects array
  tradingCardObjects.forEach(obj => {
    // Iterate through each key-value pair in the object
    for (const key in obj) {
      const value = obj[key] ? 'true' : 'false'; // Convert boolean to string
      // Check if the key already exists in the tradingCardData object
      if (tradingCardData.hasOwnProperty(key)) {
        // If the key exists, push the value into the existing array
        tradingCardData[key].push(value);
      } else {
        // If the key doesn't exist, create a new array with the value
        tradingCardData[key] = [value];
      }
    }
  });

  return tradingCardData;
};

export const searchForKey = (array, keyToSearch) => {
  const lowercasedKeyToSearch = keyToSearch.toLowerCase();
  for (const obj of array) {
    for (const key in obj) {
      if (key.toLowerCase() === lowercasedKeyToSearch) {
        return obj[key];
      }
    }
  }
  return false;
};

export const findKeyBySubstring = (obj, substring) => {
  const result = {};
  for (const key in obj) {
    if (key.toLowerCase().includes(substring.toLowerCase())) {
      result[key] = obj[key];
    }
  }
  return result;
};

export const removeTrailingDot = input => {
  if (input.endsWith('.')) {
    return input.slice(0, -1);
  }
  return input;
};

export const getFormatKeyDetails = publicData => {
  const keys = Object.keys(publicData);
  const foundKey = keys.find(key => key.includes('format'));
  return foundKey || ' ';
};

export const getLabelByFormat = (publicData, formatDetails) => {
  const formatKey = findKeyBySubstring(publicData, 'format');

  if (!formatKey || !Array.isArray(formatDetails)) return null; // handle undefined or null values

  const formatValue = publicData[Object.keys(formatKey)[0]];
  const matchingFormat = formatDetails.find(f => f.option === formatValue);
  return matchingFormat ? matchingFormat.label : null;
};

export const extendBounds = (originalBounds, distanceToAdd) => {
  // Conversion factor for degrees to kilometers
  var degreesToKilometers = 111.32; // Approximately, varies with latitude

  // Calculate new bounds
  var newBounds = {
    ne: {
      lat: originalBounds.ne.lat + distanceToAdd / degreesToKilometers,
      lng:
        originalBounds.ne.lng +
        distanceToAdd / (degreesToKilometers * Math.cos((originalBounds.ne.lat * Math.PI) / 180)),
    },
    sw: {
      lat: originalBounds.sw.lat - distanceToAdd / degreesToKilometers,
      lng:
        originalBounds.sw.lng -
        distanceToAdd / (degreesToKilometers * Math.cos((originalBounds.sw.lat * Math.PI) / 180)),
    },
  };

  // Use Mapbox's LngLatBounds to create a new bounds object
  var newLatLngBounds = new mapboxgl.LngLatBounds(
    new mapboxgl.LngLat(newBounds.sw.lng, newBounds.sw.lat),
    new mapboxgl.LngLat(newBounds.ne.lng, newBounds.ne.lat) // swapped sw and ne here
  );

  // Extend the bounds to include the original bounds
  newLatLngBounds.extend(new mapboxgl.LngLat(originalBounds.ne.lng, originalBounds.ne.lat));
  newLatLngBounds.extend(new mapboxgl.LngLat(originalBounds.sw.lng, originalBounds.sw.lat));

  // Create LatLng objects for the corners with parsed floats
  var swLatLng = new LatLng(
    parseFloat(newLatLngBounds._sw.lat.toFixed(6)),
    parseFloat(newLatLngBounds._sw.lng.toFixed(6))
  );
  var neLatLng = new LatLng(
    parseFloat(newLatLngBounds._ne.lat.toFixed(6)),
    parseFloat(newLatLngBounds._ne.lng.toFixed(6))
  );

  return new LatLngBounds(neLatLng, swLatLng);
};

export const convertToUnixTimestamps = slots => {
  return isArrayLength(slots)
    ? slots.map(slot => {
        // Assume t.attributes.start is in a valid date string format
        let startDate = new Date(slot.attributes.start);
        // Get Unix timestamp (milliseconds since epoch) and convert to seconds
        return Math.floor(startDate.getTime() / 1000);
      })
    : [];
};

// Filter slots by given UNIX timestamps and ensure the dates are in the future
export const filterSlotsByDates = (dates, slots) => {
  const currentUnix = Math.floor(Date.now() / 1000);

  return isArrayLength(slots)
    ? slots.filter(slot => {
        // Convert ISO date string to UNIX timestamp within the filter function
        const slotUnix = Math.floor(new Date(slot.attributes.start).getTime() / 1000);

        // Check if the slot start time is in the future and in the provided dates
        return dates.includes(slotUnix) && slotUnix > currentUnix;
      })
    : [];
};

export const getNextEventInfo = slots => {
  if (!Array.isArray(slots) || slots.length === 0) {
    return null;
  }

  const currentTime = moment(); // Current time

  // Convert millisecond timestamps to moments and sort them
  const formattedSlots = slots
    .map(ts => moment.unix(Math.floor(ts / 1000)))
    .sort((a, b) => a.unix() - b.unix());

  let nextEvent = null;
  let nextEventUnix = null;

  // Find the next event that is equal to or after the current time
  for (const slot of formattedSlots) {
    if (slot.isSameOrAfter(currentTime)) {
      nextEventUnix = slot.valueOf(); // Store in milliseconds
      nextEvent = slot.format('DD.MM.YYYY; HH:mm');
      break;
    }
  }

  // If no future event is found, return null
  if (!nextEventUnix) {
    return null;
  }

  return { nextEvent, nextEventUnix };
};

export const generateDefaultDateRange = () => {
  const today = new Date();
  const startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate()); // Start of today
  const endDate = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate()); // One month from today

  // Format the dates as YYYY-MM-DD
  const formattedStartDate = startDate.toISOString().split('T')[0];
  const formattedEndDate = endDate.toISOString().split('T')[0];

  return `${formattedStartDate},${formattedEndDate}`;
};

// Helper function to process `nextEvent` and format the date
export const formatEventDate = nextEvent => {
  // Convert `nextEvent` to a number, just in case it's a string
  const nextEventNumber = Number(nextEvent);

  // Check if `nextEvent` is in seconds or milliseconds
  const timestampInSeconds =
    nextEventNumber.toString().length === 10 ? nextEventNumber : nextEventNumber / 1000;

  // Format the date using moment.unix
  return moment.unix(timestampInSeconds).format('DD.MM.YYYY HH:mm') + ' Uhr';
};

/**
 * Switch elements at the given indices in an array.
 *
 * @param {Array} array - The array containing elements to be switched.
 * @param {number} index1 - The first index to switch.
 * @param {number} index2 - The second index to switch.
 * @returns {Array} - The array with switched elements.
 */
export const switchArrayIndices = (array, index1, index2) => {
  // Ensure indices are within array bounds
  if (index1 < 0 || index1 >= array.length || index2 < 0 || index2 >= array.length) {
    throw new Error('Index out of bounds');
  }

  // Switch elements at the given indices
  const temp = array[index1];
  array[index1] = array[index2];
  array[index2] = temp;

  return array;
};
