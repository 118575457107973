export const makeStrapiFilters = (filterArr) => {
  return (filterArr && filterArr.length)
    ? `${filterArr.map((st) => {
      const { type, keyName, keyFilter, keyValue } = st;
      return keyFilter ? `${type}[${keyName}][${keyFilter}]=${keyValue}` : `${type}[${keyName}]=${keyValue}`;
    }).join("&")}`
    : ""
};


export const STRAPI_TRANSACTIONS = "transactions";

