import React, { useState } from 'react';
import { array, bool, func, number, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage, intlShape, injectIntl } from '../../../util/reactIntl';
import { timestampToDate, HOUR_MS } from '../../../util/dates';
import { propTypes } from '../../../util/types';
import { BOOKING_PROCESS_NAME, FREE_PROCESS_NAME } from '../../../transactions/transaction';
import {
  useShowListingQuery,
  useShowListingAvailabilityExceptionsQuery,
  useShowListingPlayersQuery,
  useIncreaseViewCountMutation,
} from '../../../ducks/ListingApi';

import {
  Form,
  H6,
  PrimaryButton,
  FieldTextInput,
  NamedLink,
  Modal,
  H4,
  AvatarSmall,
} from '../../../components';

import EstimatedCustomerBreakdownMaybe from '../EstimatedCustomerBreakdownMaybe';
//import FieldDateAndTimeInput from './FieldDateAndTimeInput';
import DateSelect from './DateSelect';
import {
  isListingPaymentModeOnline,
  isListingPaymentModeOnSite,
  isListingPaymentModeOnlineAndPayOnSite,
  isListingPaymentModeLinkToAnotherWebPage,
  getListingDefaultProcessName,
  isUserCompany,
  isUserPrivatePerson,
  getDateTimeFormat,
  getDateFormat,
  isSeatFull,
} from '../../../util/data';
import Seats from './Seats';

import css from './BookingTimeForm.module.css';
import { findValidGameId, getDisplayName, getEventDates } from '../../../util/dataExtractors';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import routeConfiguration from '../../../routing/routeConfiguration';
import { createResourceLocatorString } from '../../../util/routes';
import { filterSlotsByDates, isArrayLength } from '../../../util/genericHelpers';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '../../../ducks/auth.duck';
import { selectBookedSlots } from '../../../containers/ListingPage/ListingPage.duck';
import BookingList from '../BookingList';

const profilePath = user => `/u/${user.id.uuid}`;

export const BookingTimeFormComponent = props => {
  const { currentUser } = props;
  const [itemStartDate, setItemStartDate] = useState(null);
  const [showBookedDateValidation, setShowBookedDateValidation] = useState(false);
  const [selectedBookings, setSelectedBookings] = useState([]);
  const [playersListModalOpen, setPlayersListModalOpen] = useState(false);
  const {
    data: enhancedListing,
    isLoading: enhancedListingIsLoading,
    isError: enhancedListingIsError,
  } = useShowListingQuery(props.listingId.uuid);

  const {
    data: availabilityExceptions,
    isLoading: availabilityExceptionsIsLoading,
    isError: availabilityExceptionsIsError,
  } = useShowListingAvailabilityExceptionsQuery(
    {
      listingId: props.listingId.uuid,
      start: itemStartDate?.toISOString(),
      end: new Date((itemStartDate?.getTime() || Date.now()) + 2 * HOUR_MS).toISOString(),
    },
    {
      skip: !itemStartDate,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: players = [],
    isLoading: playersIsLoading, // eslint-disable-line no-unused-vars
    isError: playersIsError, // eslint-disable-line no-unused-vars
  } = useShowListingPlayersQuery(
    {
      listingId: props.listingId.uuid,
      start: itemStartDate?.toISOString(),
      end: new Date((itemStartDate?.getTime() || Date.now()) + 2 * HOUR_MS).toISOString(),
    },
    {
      skip: !itemStartDate,
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    increaseViewCount,
    // eslint-disable-next-line no-unused-vars
    { isLoading: increaseViewCountInProgress, error: increaseViewCountError },
  ] = useIncreaseViewCountMutation();

  const isCurrentUserPlayer = players.find(player => player?.id?.uuid === currentUser?.id?.uuid);
  const isCurrentUserPlayerForFullySlots =
    isArrayLength(selectedBookings) &&
    selectedBookings.find(c => c?.currentUser?.id?.uuid?.includes(currentUser?.id?.uuid));

  const isCompanyUser = isUserCompany(currentUser);

  const handleFormSubmit = e => {
    props.onSubmit(e);
  };

  const handleToBookOnSite = form => {
    return e => {
      form.batch(() => {
        form.change('processName', FREE_PROCESS_NAME);
      });
    };
  };

  // When the values of the form are updated we need to fetch
  // lineItems from this template's backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the orderData object.
  const handleOnChange = formValues => {
    const { bookingStartTime, bookingEndTime } = formValues.values;
    const startDate = bookingStartTime ? timestampToDate(bookingStartTime) : null;
    const endDate = bookingEndTime ? timestampToDate(bookingEndTime) : null;

    const listingId = props.listingId;
    const isOwnListing = props.isOwnListing;

    // Note: we expect values bookingStartTime and bookingEndTime to be strings
    // which is the default case when the value has been selected through the form
    const isStartBeforeEnd = bookingStartTime < bookingEndTime;

    if (bookingStartTime && bookingEndTime && isStartBeforeEnd && !props.fetchLineItemsInProgress) {
      setItemStartDate(startDate);
      props.onFetchTransactionLineItems({
        orderData: { bookingStart: startDate, bookingEnd: endDate },
        listingId,
        isOwnListing,
      });
    }
  };

  const handleClickLinkToExternalWebPage = e => {
    increaseViewCount(props.listingId.uuid);
  };

  const {
    rootClassName,
    className,
    price: unitPrice,
    dayCountAvailableForBooking,
    marketplaceName,
    registeredPlayers,
    confirmPayment,
    userTransactions,
    ...rest
  } = props;


  const classes = classNames(rootClassName || css.root, className);
  const processName = getListingDefaultProcessName(rest.listing);
  const initialValues = {
    processName,
  };


  const isAuthenticated = useSelector(selectIsAuthenticated);
  const { bookedSlots, bookedTransactions } = useSelector(selectBookedSlots);

  return (
    <FinalForm
      {...rest}
      unitPrice={unitPrice}
      onSubmit={handleFormSubmit}
      initialValues={initialValues}
      render={fieldRenderProps => {
        const {
          endDatePlaceholder,
          startDatePlaceholder,
          form,
          pristine,
          handleSubmit,
          intl,
          isOwnListing,
          listingId,
          values,
          monthlyTimeSlots,
          allTimeSlots,
          allTimeSlotsInProgress,
          allTimeSlotsError,
          allTimeSlotsWholeYearLoaded,
          onFetchTimeSlots,
          timeZone,
          lineItems,
          fetchLineItemsInProgress,
          fetchLineItemsError,
          listing,
          formId,
          history,
          onManageDisableScrolling,
          playerAcceptedDateWise,
          playerAcceptedData,
          playerRegisteredDateWise
        } = fieldRenderProps;
      console.log(listing, "listing");
        const txData = listing?.attributes?.publicData?.txData;
        const seatFull = isSeatFull(listing, playerAcceptedDateWise)


        const paymentModeOnline = isListingPaymentModeOnline(listing);
        const paymentModeOnSite = isListingPaymentModeOnSite(listing);
        const validGameId = findValidGameId(listing, currentUser);
        const paymentModeOnlineAndPayOnSite = isListingPaymentModeOnlineAndPayOnSite(listing);
        const paymentModeLinkToAnotherWebPage = isListingPaymentModeLinkToAnotherWebPage(listing);
        const eventDates = getEventDates(listing);
        const isPrivatePerson = isUserPrivatePerson(currentUser);

        const startTime = values && values.bookingStartTime ? values.bookingStartTime : null;
        const endTime = values && values.bookingEndTime ? values.bookingEndTime : null;
        const startDate = startTime ? timestampToDate(startTime) : null;
        const endDate = endTime ? timestampToDate(endTime) : null;

        // This is the place to collect breakdown estimation data. See the
        // EstimatedCustomerBreakdownMaybe component to change the calculations
        // for customized payment processes.
        const breakdownData =
          startDate && endDate
            ? {
              startDate,
              endDate,
            }
            : null;

        const showEstimatedBreakdown =
          breakdownData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

        const dirtyValues = form.getState().values;
        const { bookingStartTime } = dirtyValues;
        const oneHourFromNow = new Date(Date.now() + HOUR_MS).getTime();
        const bookingDisabled = bookingStartTime ? Number(bookingStartTime) < oneHourFromNow : true;

        const openPlayersModal = e => {
          e.preventDefault();
          setPlayersListModalOpen(true);
        };

        const playersLink = (
          <button className={css.registeredPlayersLink} onClick={openPlayersModal}>
            <FormattedMessage
              id="BookingTimeForm.currentlyPlayers"
              values={{ playersCount: selectedBookings?.length || 0 }}
            />
          </button>
        );
        // to check particular slot is booked by user or not 
        const checkBookingDates = (transaction) => {
          const { bookingStartTime, bookingEndTime } = values;
          const { bookingEnd, bookingStart } = transaction?.protectedData || {};

          return (getDateFormat(bookingStart) == getDateTimeFormat(bookingStartTime)) && (getDateFormat(bookingEnd) == getDateTimeFormat(bookingEndTime));
        };

        const organizer_declines = userTransactions?.findLast((transaction) => transaction?.lastTransition == "transition/decline" && checkBookingDates(transaction)) || true;
        const inquiry_initiated = userTransactions?.filter((transaction) => transaction?.lastTransition != "transition/decline" && checkBookingDates(transaction)).length > 0;
        const organizer_accepted = userTransactions?.findLast((transaction) => transaction?.lastTransition == "transition/accept" && checkBookingDates(transaction)) || false;

        return (
          <Form onSubmit={handleSubmit} className={classes} enforcePagePreloadFor="CheckoutPage">
            <FormSpy
              subscription={{ values: true }}
              onChange={values => {
                handleOnChange(values);
              }}
            />
            <FieldTextInput
              type="hidden"
              id={formId ? `${formId}.processName` : 'processName'}
              name="processName"
            />
            <H6 as="h3" className={css.dateTitle}>
              <FormattedMessage id="BookingTimeForm.dateTitle" />
            </H6>
            <BookingList
              txData={txData}
              allTimeSlots={allTimeSlots}
              showBookedDateValidation={showBookedDateValidation}
              setShowBookedDateValidation={setShowBookedDateValidation}
              selectedBookings={selectedBookings}
              setSelectedBookings={setSelectedBookings}
              startDateInputProps={{
                label: intl.formatMessage({ id: 'BookingTimeForm.bookingStartTitle' }),
                placeholderText: startDatePlaceholder,
              }}
              endDateInputProps={{
                label: intl.formatMessage({ id: 'BookingTimeForm.bookingEndTitle' }),
                placeholderText: endDatePlaceholder,
              }}
              className={css.bookingDates}
              listingId={listingId}
              onFetchTimeSlots={onFetchTimeSlots}
              monthlyTimeSlots={monthlyTimeSlots}
              onClearBookedValidation={() => {
                setShowBookedDateValidation(false);
                setSelectedBookings(false);
              }}
              allTimeSlotsInProgress={allTimeSlotsInProgress}
              allTimeSlotsError={allTimeSlotsError}
              allTimeSlotsWholeYearLoaded={allTimeSlotsWholeYearLoaded}
              values={values}
              intl={intl}
              form={form}
              pristine={pristine}
              timeZone={timeZone}
              dayCountAvailableForBooking={dayCountAvailableForBooking}
            />
            {/* {monthlyTimeSlots && timeZone ? (
              <DateSelect
                startDateInputProps={{
                  label: intl.formatMessage({ id: 'BookingTimeForm.bookingStartTitle' }),
                  placeholderText: startDatePlaceholder,
                }}
                endDateInputProps={{
                  label: intl.formatMessage({ id: 'BookingTimeForm.bookingEndTitle' }),
                  placeholderText: endDatePlaceholder,
                }}
                className={css.bookingDates}
                listingId={listingId}
                onFetchTimeSlots={onFetchTimeSlots}
                monthlyTimeSlots={monthlyTimeSlots}
                allTimeSlots={allTimeSlots}
                onClearBookedValidation={() => {
                  setShowBookedDateValidation(false);
                  setSelectedBookings(false);
                }}
                allTimeSlotsInProgress={allTimeSlotsInProgress}
                allTimeSlotsError={allTimeSlotsError}
                allTimeSlotsWholeYearLoaded={allTimeSlotsWholeYearLoaded}
                values={values}
                intl={intl}
                form={form}
                pristine={pristine}
                timeZone={timeZone}
                showBookedDateValidation={showBookedDateValidation}
                dayCountAvailableForBooking={dayCountAvailableForBooking}
              />
            ) : null} */}

            {showEstimatedBreakdown && !showBookedDateValidation ? (
              <div className={css.priceBreakdownContainer}>
                <Seats
                  intl={intl}
                  playerAcceptedDateWise={playerAcceptedDateWise}
                  playerAcceptedData={playerAcceptedData}
                  playerRegisteredDateWise={playerRegisteredDateWise}
                  registeredPlayers={registeredPlayers}
                  confirmPayment={confirmPayment}
                  userTransactions={userTransactions}
                  timeZone={timeZone}
                  breakdownData={breakdownData}
                  enhancedListing={enhancedListing}
                  enhancedListingIsLoading={enhancedListingIsLoading}
                  enhancedListingIsError={enhancedListingIsError}
                  monthlyTimeSlots={monthlyTimeSlots}
                  availabilityExceptions={availabilityExceptions}
                  availabilityExceptionsIsLoading={availabilityExceptionsIsLoading}
                  availabilityExceptionsIsError={availabilityExceptionsIsError}
                  listingId={listingId}
                  seatsTotal={listing?.attributes?.publicData?.seats}
                />
                <H6 as="h3" className={css.bookingBreakdownTitle}>
                  <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
                </H6>
                <hr className={css.totalDivider} />
                <EstimatedCustomerBreakdownMaybe
                  breakdownData={breakdownData}
                  lineItems={lineItems}
                  timeZone={timeZone}
                  currency={unitPrice.currency}
                  marketplaceName={marketplaceName}
                  processName={BOOKING_PROCESS_NAME}
                />
              </div>
            ) : null}

            {isArrayLength(selectedBookings) && (
              <div className={css.bookingListDetails}>
                <h3>
                  <FormattedMessage id="BookingTimeForm.bookingListDetails" />
                </h3>
                <div className={css.bookingDetail}>
                  <p>
                    <FormattedMessage
                      id="BookingTimeForm.currentlyPlayersRegistered"
                      values={{
                        playersLink,
                        seats: selectedBookings?.length || 0,
                      }}
                    />
                  </p>
                </div>
              </div>
            )}

            {fetchLineItemsError ? (
              <span className={css.sideBarError}>
                <FormattedMessage id="BookingTimeForm.fetchLineItemsError" />
              </span>
            ) : null}
            {inquiry_initiated && !organizer_accepted && (<span className={css.sideBarError}>
              <FormattedMessage id="BookingTimeForm.inquiryRegistered" />
            </span>)}

            {
              organizer_accepted && (
                <span className={css.sideBarError}>
                  <FormattedMessage id="BookingTimeForm.acceptedRegistered" />
                </span>
              )
            }
            {/* {!(
              paymentModeOnline ||
              paymentModeOnSite ||
              paymentModeOnlineAndPayOnSite ||
              paymentModeLinkToAnotherWebPage
            ) && (
                <span className={css.sideBarError}>
                  <FormattedMessage id="BookingTimeForm.noPaymentModeError" />
                </span>
              )} */}

            {increaseViewCountError && (
              <span className={css.sideBarError}>
                <FormattedMessage id="BookingTimeForm.increaseViewCountError" />
              </span>
            )}
            {(showBookedDateValidation || isCurrentUserPlayerForFullySlots) && (
              <span className={css.bookedDateValidation}>
                <FormattedMessage
                  id={
                    isCurrentUserPlayerForFullySlots
                      ? 'BookingTimeForm.youAlreadyRegistered'
                      : 'BookingTimeForm.showBookedDateValidation'
                  }
                />
              </span>
            )}
            {paymentModeLinkToAnotherWebPage && (
              <a
                className={css.linkToExternalWebPage}
                onClick={handleClickLinkToExternalWebPage}
                href={listing?.attributes?.publicData?.linkToExternalWebPage}
                target="_blank"
                rel="noreferrer"
              >
                <FormattedMessage id="BookingTimeForm.linkToExternalWebPage" />
              </a>
            )}
            {isCompanyUser ? (
              <>
                <PrimaryButton
                  className={classNames(
                    paymentModeOnlineAndPayOnSite && css.requestToBookOnSite,
                    css.addCardInformation
                  )}
                  type="button"
                  disabled
                >
                  <FormattedMessage id="BookingTimeForm.disabledButton" />
                </PrimaryButton>
                <span className={css.cardInformation}>
                  <FormattedMessage id="BookingTimeForm.disabledText" />
                </span>
              </>
            ) : !validGameId && !paymentModeLinkToAnotherWebPage ? (
              <>
                <PrimaryButton
                  className={classNames(
                    paymentModeOnlineAndPayOnSite && css.requestToBookOnSite,
                    css.addCardInformation,
                    {
                      [css.greenButton]: !isAuthenticated,
                      [css.blueButton]: isAuthenticated && isPrivatePerson,
                    }
                  )}
                  type="button"
                  onClick={() =>
                    history.push(
                      createResourceLocatorString(
                        'TradingCardAccountsPage',
                        routeConfiguration(),
                        {},
                        {}
                      )
                    )
                  }
                >
                  <FormattedMessage
                    id={
                      isAuthenticated
                        ? 'BookingTimeForm.addCardInformation'
                        : 'BookingTimeForm.nonLoggedrequestToBookOnSite'
                    }
                  />
                </PrimaryButton>
                <span className={css.cardInformation}>
                  <FormattedMessage id="BookingTimeForm.tradingCardIdInfo" />
                </span>
              </>
            ) : !isCurrentUserPlayer ? (
              <>
                <div className={css.submitButton}>
                  {/* {showBookedDateValidation && selectedBookings.length > 0
                    ? null
                    : (paymentModeOnline || paymentModeOnlineAndPayOnSite) && (
                      <PrimaryButton
                        type="submit"
                        inProgress={fetchLineItemsInProgress}
                        disabled={bookingDisabled || showBookedDateValidation}
                      >
                        <FormattedMessage id="BookingTimeForm.requestToBookOnline" />
                      </PrimaryButton>
                    )} */}

                  {(paymentModeOnSite || paymentModeOnlineAndPayOnSite) && organizer_declines && !inquiry_initiated && !organizer_accepted && !isOwnListing && (
                    <PrimaryButton
                      className={classNames(
                        paymentModeOnlineAndPayOnSite && css.requestToBookOnSite
                      )}
                      type="submit"
                      inProgress={fetchLineItemsInProgress}
                      onClick={handleToBookOnSite(form)}
                      disabled={bookingDisabled || showBookedDateValidation}
                    >
                      {!seatFull ? <FormattedMessage id="BookingTimeForm.requestToBookOnSite" /> : <FormattedMessage id="BookingTimeForm.waiting" />}
                    </PrimaryButton>
                  )}

                </div>
                {showBookedDateValidation && selectedBookings.length > 0 ? null : (
                  <p className={css.finePrint}>
                    <FormattedMessage
                      id={
                        paymentModeLinkToAnotherWebPage
                          ? 'BookingTimeForm.registerExternalWebPage'
                          : isOwnListing
                            ? 'BookingTimeForm.ownListing'
                            : 'BookingTimeForm.youWontBeChargedInfo'
                      }
                    />
                  </p>
                )}
              </>
            ) : (
              <p className={css.finePrint}>
                <FormattedMessage id="BookingTimeForm.youAlreadyRegistered" />
              </p>
            )}
            <Modal
              id="OrderPanel.playersList"
              isOpen={playersListModalOpen}
              onClose={() => setPlayersListModalOpen(false)}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <div className={css.playersList}>
                <H4>
                  <FormattedMessage id="BookingTimeForm.registeredPlayers" />
                </H4>
                {isArrayLength(selectedBookings) &&
                  selectedBookings.map((b, index) => {
                    const user = b?.currentUser;
                    const displayName = getDisplayName(user);
                    return (
                      <div className={css.player} key={`user-${index}`}>
                        <AvatarSmall className={css.playerAvatar} user={user} />
                        <a href={profilePath(user)} target="_blank" rel="noreferrer">
                          {displayName}
                        </a>
                      </div>
                    );
                  })}
              </div>
            </Modal>
          </Form>
        );
      }}
    />
  );
};

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  marketplaceName: string.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,
  timeZone: string.isRequired,

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,

  dayCountAvailableForBooking: number.isRequired,
};

const BookingTimeForm = compose(injectIntl)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;
