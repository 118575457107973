import React, { useState, useEffect } from 'react';
import moment from 'moment';
import css from './OrderPanel.module.css';
import classNames from 'classnames';
import { isArrayLength } from '../../util/genericHelpers';
import {
  isInRange,
  timeOfDayFromLocalToTimeZone,
  isDateSameOrAfter,
  monthIdString,
  getStartOf,
} from '../../util/dates';

import { IconArrowHead, IconSpinner, FieldTextInput } from '../../components';

import {
  getAllTimeValues,
  getFirstAndSingleTimeSlot,
  getTimeSlotsSet,
  TIME_SLOTS_PER_SET,
} from './BookingTimeForm/timeslotHelpers';

const TODAY = new Date();

const nextMonthFn = (currentMoment, timeZone) =>
  getStartOf(currentMoment, 'month', timeZone, 1, 'months');
const prevMonthFn = (currentMoment, timeZone) =>
  getStartOf(currentMoment, 'month', timeZone, -1, 'months');

const endOfRange = (date, dayCountAvailableForBooking, timeZone) => {
  return getStartOf(date, 'day', timeZone, dayCountAvailableForBooking - 1, 'days');
};

const filterUniqueUsers = bookings => {
  if (!isArrayLength(bookings)) {
    return [];
  }

  // Create a map to track the highest index for each unique user ID
  const uniqueUsersMap = new Map();

  bookings.forEach((booking, index) => {
    const userId = booking.currentUser.id.uuid;

    // Update the map with the highest index found for the user ID
    uniqueUsersMap.set(userId, index);
  });

  // Filter bookings to only include those with the highest index
  return bookings.filter((booking, index) => {
    const userId = booking.currentUser.id.uuid;
    return uniqueUsersMap.get(userId) === index;
  });
};

const BookingList = props => {
  const {
    txData,
    showBookedDateValidation,
    setShowBookedDateValidation,
    setSelectedBookings,
    form,
    formId,
    monthlyTimeSlots,
    allTimeSlots,
    allTimeSlotsInProgress,
    timeZone,
    intl,
    onClearBookedValidation,
    values
  } = props;

  const initialMonth = getStartOf(TODAY, 'month', props.timeZone);
  const [currentMonth, setCurrentMonth] = useState(initialMonth);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(() => allTimeSlots.length ? allTimeSlots[0] : null);
  const [currentSlotIndex, setCurrentSlotIndex] = useState(0);
  const [activeSlot, setActiveSlot] = useState(null); // State to track the active slot

  // Function to parse currentUser and group bookings by bookingStart time
  const groupBookingsByStartTime = bookings => {
    return bookings.reduce((acc, booking) => {
      const currentUser = JSON.parse(booking.currentUser);
      const bookingStart = booking.bookingStart;
      if (!acc[bookingStart]) {
        acc[bookingStart] = [];
      }
      acc[bookingStart].push({ ...booking, currentUser });
      return acc;
    }, {});
  };

  const groupedBookings = isArrayLength(txData) && groupBookingsByStartTime(txData);
  const groupedBookingKeys = Object.keys(groupedBookings).map(startTime => ({
    type: 'booking',
    data: groupedBookings[startTime],
    startTime,
  }));

  const selectedTimeSlots = getTimeSlotsSet({ allTimeSlots, index: currentSlotIndex });
  const formattedTimeSlots = isArrayLength(allTimeSlots)
    ? allTimeSlots.map(slot => ({
        type: 'timeSlot',
        data: slot,
        startTime: slot.attributes.start.getTime(),
      }))
    : [];

  // Combine grouped bookings and time slots into one unified array
  let unifiedSlots = [...groupedBookingKeys, ...formattedTimeSlots];

  // Get the current time
  const now = moment();

  // Filter out bookings that have the same start time as a timeslot
  unifiedSlots = unifiedSlots.filter((slot, index, self) => {
    // If the slot is of type 'booking' and there is another slot with the same startTime of type 'timeSlot', filter it out
    if (slot.type === 'booking') {
      return !self.some(
        otherSlot => otherSlot.type === 'timeSlot' && otherSlot.startTime == slot.startTime
      );
    }
    return true;
  });

  // Filter out past events
  unifiedSlots = unifiedSlots.filter(slot => {
    const startTime = Number(slot.startTime);
    // Check if the slot's start time is in the future or the same as the current time
    return moment(startTime).isAfter(now) || moment(startTime).isSame(now, 'minute');
  });

  // Pagination logic: calculate visible slots
  const visibleSlots = unifiedSlots.slice(currentSlotIndex, currentSlotIndex + TIME_SLOTS_PER_SET);
  const handleBookingClick = bookings => {
    setSelectedBookings(filterUniqueUsers(bookings));
    setActiveSlot(bookings[0].bookingStart); // Set the active slot
    setShowBookedDateValidation(true);
  };

  //preselecting timeslot 
  useEffect(() => {
    setSelectedTimeSlot(allTimeSlots[0]);
  }, [allTimeSlots]);

  useEffect(() => {
    if (selectedTimeSlot) {
      onTimeSlotChange(selectedTimeSlot);
      setActiveSlot(selectedTimeSlot.attributes.start.getTime()); // Set the active slot
    }
  }, [selectedTimeSlot]);

  useEffect(() => {
    const monthId = monthIdString(currentMonth, timeZone);
    const currentMonthData = monthlyTimeSlots[monthId];
    if (!currentMonthData) {
      fetchMonthData(currentMonth);
    }
  }, [currentMonth]);

  const fetchMonthData = date => {
    const { listingId, timeZone, onFetchTimeSlots, dayCountAvailableForBooking } = props;
    const endOfRangeDate = endOfRange(TODAY, dayCountAvailableForBooking, timeZone);

    if (isInRange(date, TODAY, endOfRangeDate)) {
      const start = isDateSameOrAfter(TODAY, date) ? TODAY : date;
      const nextMonthDate = nextMonthFn(date, timeZone);
      const end = isDateSameOrAfter(nextMonthDate, endOfRangeDate)
        ? getStartOf(endOfRangeDate, 'day', timeZone)
        : nextMonthDate;

      onFetchTimeSlots(listingId, start, end, timeZone);
    }
  };

  const onTimeSlotChange = timeSlot => {
    const startDate = timeOfDayFromLocalToTimeZone(timeSlot.attributes.start, timeZone);
    const timeSlotsOnSelectedDate = [timeSlot];

    const { startTime, endDate, endTime } = getAllTimeValues(
      intl,
      timeZone,
      timeSlotsOnSelectedDate,
      startDate
    );

    const selectedSlotIndex =
      isArrayLength(allTimeSlots) &&
      allTimeSlots.findIndex(t => t?.id?.uuid === timeSlot?.id?.uuid);

    const currentSlot = allTimeSlots[selectedSlotIndex]?.attributes?.start;
    const nextSlot = allTimeSlots[selectedSlotIndex + 1]?.attributes?.start;
    const calculatedNextSlot = nextSlot
      ? moment(nextSlot)
          .startOf('day')
          .toDate()
      : null;

    const isNextSlotSameDay = nextSlot && moment(currentSlot).isSame(moment(nextSlot), 'day');
    const endTimeOfNextSlot = isNextSlotSameDay ? calculatedNextSlot : timeSlot.attributes.end;

    form.batch(() => {
      form.change('bookingStartTime', startTime);
      form.change('bookingEndDate', { date: endTimeOfNextSlot });
      form.change('bookingEndTime', endTimeOfNextSlot.getTime());
    });
  };

  const onClickTimeSlot = timeSlot => e => {
    onClearBookedValidation();
    setSelectedTimeSlot(timeSlot);
    setActiveSlot(timeSlot.attributes.start.getTime()); // Set the active slot
  };

  const onClickPrev = e => {
    let index = currentSlotIndex - TIME_SLOTS_PER_SET;
    if (index >= 0) {
      setCurrentSlotIndex(index);
    }
  };

  const fetchNextMonth = () => {
    if (allTimeSlotsInProgress) {
      return;
    }

    const nextMonthDate = nextMonthFn(currentMonth, timeZone);

    if (moment(nextMonthDate).isAfter(moment(initialMonth).add(1, 'y'), 'year')) {
      return;
    }

    const monthId = monthIdString(nextMonthDate, timeZone);
    const monthData = monthlyTimeSlots[monthId];

    if (monthData) {
      if (monthData.fetchTimeSlotsInProgress) {
        return;
      }
      if (monthData.fetchTimeSlotsError) {
        fetchMonthData(nextMonthDate);
      }
    }

    setCurrentMonth(nextMonthDate);
  };

  const onClickNext = e => {
    if (currentSlotIndex + TIME_SLOTS_PER_SET < unifiedSlots.length) {
      setCurrentSlotIndex(currentSlotIndex + TIME_SLOTS_PER_SET);
    } else {
      fetchNextMonth();
    }
  };
 
  return (
    <div className={css.slotsWrapper}>
      <div>
        {visibleSlots.map(slot => (
          <div
            key={slot.startTime}
            className={classNames(css.bookedSlots, {
              [css.activeSlot]: activeSlot == slot.startTime, // Apply the active class
            })}
            role="button"
            onClick={() =>
              slot.type === 'booking' ? handleBookingClick(slot.data) : onClickTimeSlot(slot.data)()
            }
          >
            {slot.type === 'booking'
              ? moment.unix(slot.startTime / 1000).format('dd. DD.MM.YYYY; HH:mm [Uhr]')
              : moment(slot.data.attributes.start).format(
                  intl.formatMessage({ id: 'BookingTimeForm.dateFormat' })
                )}

            {slot.type === 'timeSlot' && intl.formatMessage({ id: 'BookingTimeForm.clock' })}
          </div>
        ))}
      </div>
      <div
        className={classNames(css.slotsBox, {
          [`${css.singleSlotBox}`]: visibleSlots.length === 1,
        })}
      >
        <div className={css.arrowLeft} onClick={onClickPrev}>
          {currentSlotIndex !== 0 && <IconArrowHead direction="left" rootClassName={css.arrow} />}
        </div>
        <div className={css.iconSpinner}>{allTimeSlotsInProgress && <IconSpinner />}</div>
        <div className={css.arrowRight} onClick={onClickNext}>
          {currentSlotIndex + TIME_SLOTS_PER_SET < unifiedSlots.length && (
            <IconArrowHead direction="right" rootClassName={css.arrow} />
          )}
        </div>
      </div>
      <FieldTextInput
        type="hidden"
        name="bookingStartDate"
        id={formId ? `${formId}.bookingStartDate` : 'bookingStartDate'}
      />
      <FieldTextInput
        type="hidden"
        name="bookingStartTime"
        id={formId ? `${formId}.bookingStartTime` : 'bookingStartTime'}
      />
      <FieldTextInput
        type="hidden"
        name="bookingEndTime"
        id={formId ? `${formId}.bookingEndTime` : 'bookingEndTime'}
      />
    </div>
  );
};

export default BookingList;
