import pick from 'lodash/pick';

import { types as sdkTypes, createImageVariantConfig } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { onCreateTransaction, onGetListingTransactions, queryListingExceptions, transactionLineItems } from '../../util/api';
import * as log from '../../util/log';
import { denormalisedResponseEntities, onGetMessageParams, onGetTransactionParam, utcFormatDateFromUnix } from '../../util/data';
import { findNextBoundary, getStartOf, monthIdString } from '../../util/dates';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { getProcess, isBookingProcessAlias } from '../../transactions/transaction';
import { fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import moment from 'moment';
import { convertToUnixTimestamps, isArrayLength } from '../../util/genericHelpers';
import { reverse, sortBy } from 'lodash';
import { STRAPI_TRANSACTIONS, makeStrapiFilters } from '../../config/configStrapiUrls';

const { UUID } = sdkTypes;

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const FETCH_REVIEWS_REQUEST = 'app/ListingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ListingPage/FETCH_REVIEWS_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/ListingPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/ListingPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/ListingPage/FETCH_TIME_SLOTS_ERROR';

export const FETCH_LINE_ITEMS_REQUEST = 'app/ListingPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/ListingPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/ListingPage/FETCH_LINE_ITEMS_ERROR';

export const SEND_INQUIRY_REQUEST = 'app/ListingPage/SEND_INQUIRY_REQUEST';
export const SEND_INQUIRY_SUCCESS = 'app/ListingPage/SEND_INQUIRY_SUCCESS';
export const SEND_INQUIRY_ERROR = 'app/ListingPage/SEND_INQUIRY_ERROR';

export const FETCH_YEAR_TIME_SLOTS_REQUEST = 'app/ListingPage/FETCH_YEAR_TIME_SLOTS_REQUEST';
export const FETCH_YEAR_TIME_SLOTS_SUCCESS = 'app/ListingPage/FETCH_YEAR_TIME_SLOTS_SUCCESS';
export const FETCH_YEAR_TIME_SLOTS_ERROR = 'app/ListingPage/FETCH_YEAR_TIME_SLOTS_ERROR';
export const FETCH_REGISTERED_PLAYERS = 'app/ListingPage/FETCH_REGISTERED_PLAYERS';
export const UPDATE_TIME_SLOTS = 'app/ListingPage/UPDATE_TIME_SLOTS';

export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/ListingPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_PLAYER_ACCEPTED = 'app/ListingPage/FETCH_PLAYER_ACCEPTED'
// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  id: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: {
    // '2022-03': {
    //   timeSlots: [],
    //   fetchTimeSlotsError: null,
    //   fetchTimeSlotsInProgress: null,
    // },
  },
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  sendInquiryInProgress: false,
  sendInquiryError: null,
  inquiryModalOpenForListingId: null,
  allTimeSlots: [],
  bookedSlots: [],
  allTimeSlotsInProgress: false,
  allTimeSlotsError: null,
  allTimeSlotsWholeYearLoaded: false,
  bookedTransactions: false,
  transactionRefs: [],
  registeredPlayers: null,
  confirmPayment: null,
  userTransactions: [],
  playerAcceptedDateWise: null,
  playerAcceptedData: null,
  playerRegisteredDateWise: null,
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }

    case UPDATE_TIME_SLOTS:
      return {
        ...state,
        bookedSlots: payload.exceptions,
        bookedTransactions: payload.bookedTransactions,
      };
    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case FETCH_REVIEWS_REQUEST:
      return { ...state, fetchReviewsError: null };
    case FETCH_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchReviewsError: payload };

    case FETCH_TIME_SLOTS_REQUEST: {
      const { listingId, monthId } = payload;

      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [payload]: {
          ...state.monthlyTimeSlots[payload],
          fetchTimeSlotsError: null,
          fetchTimeSlotsInProgress: true,
        },
      };
      const allTimeSlots = listingId ? [] : state.allTimeSlots;
      return { ...state, id: listingId.uuid, allTimeSlots, monthlyTimeSlots, allTimeSlotsInProgress: true, allTimeSlotsError: null };
    }
    case FETCH_TIME_SLOTS_SUCCESS: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          timeSlots: payload.timeSlots,
        },
      };
      const allTimeSlots = state.allTimeSlots.concat(payload.timeSlots);
      return {
        ...state,
        monthlyTimeSlots,
        allTimeSlots,
        allTimeSlotsInProgress: false,
        allTimeSlotsError: null,
      };
    }
    case FETCH_TIME_SLOTS_ERROR: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          fetchTimeSlotsError: payload.error,
        },
      };
      return {
        ...state,
        monthlyTimeSlots,
        allTimeSlotsInProgress: false,
        allTimeSlotsError: payload.error,
      };
    }

    case FETCH_LINE_ITEMS_REQUEST:
      return { ...state, fetchLineItemsInProgress: true, fetchLineItemsError: null };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return { ...state, fetchLineItemsInProgress: false, fetchLineItemsError: payload };

    case SEND_INQUIRY_REQUEST:
      return { ...state, sendInquiryInProgress: true, sendInquiryError: null };
    case SEND_INQUIRY_SUCCESS:
      return { ...state, sendInquiryInProgress: false };
    case SEND_INQUIRY_ERROR:
      return { ...state, sendInquiryInProgress: false, sendInquiryError: payload };

    case FETCH_YEAR_TIME_SLOTS_REQUEST: {
      const monthlyTimeSlots = {};
      return {
        ...state,
        monthlyTimeSlots,
        allTimeSlots: [],
        allTimeSlotsInProgress: true,
        allTimeSlotsError: null,
        allTimeSlotsWholeYearLoaded: false,
      };
    }
    case FETCH_YEAR_TIME_SLOTS_SUCCESS: {
      const monthlyTimeSlots = payload.monthlyTimeSlots;
      const allTimeSlots = payload.allTimeSlots;
      const allTimeSlotsWholeYearLoaded = payload.allTimeSlotsWholeYearLoaded;
      return {
        ...state,
        monthlyTimeSlots,
        allTimeSlots,
        allTimeSlotsInProgress: false,
        allTimeSlotsError: null,
        allTimeSlotsWholeYearLoaded,
      };
    }
    case FETCH_YEAR_TIME_SLOTS_ERROR: {
      return {
        ...state,
        allTimeSlotsInProgress: false,
        allTimeSlotsError: payload.error,
        allTimeSlotsWholeYearLoaded: false,
      };
    }
    case FETCH_REGISTERED_PLAYERS: {
      return {
        ...state,
        registeredPlayers: payload.registeredPlayers,
        userTransactions: payload.userTransactions,
        confirmPayment: payload.allRegisteredTransaction
      }
    }
    case FETCH_PLAYER_ACCEPTED: {
      return {
        ...state,
        playerAcceptedDateWise: payload.totalPlayer,
        playerAcceptedData: payload.playerData,
        playerRegisteredDateWise: payload.playersRegister
      }
    }

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = reviews => ({ type: FETCH_REVIEWS_SUCCESS, payload: reviews });
export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

export const fetchTimeSlotsRequest = payload => ({
  type: FETCH_TIME_SLOTS_REQUEST,
  payload,
});
export const fetchTimeSlotsSuccess = (monthId, timeSlots) => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: { timeSlots, monthId },
});
export const fetchTimeSlotsError = (monthId, error) => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: { monthId, error },
});
export const updateTimeSlots = payload => ({
  type: UPDATE_TIME_SLOTS,
  payload,
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchParticularTransactionTimeBased = (totalPlayer, playerData, playersRegister) => ({
  type: FETCH_PLAYER_ACCEPTED,
  payload: { totalPlayer, playerData, playersRegister }
})
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

export const sendInquiryRequest = () => ({ type: SEND_INQUIRY_REQUEST });
export const sendInquirySuccess = () => ({ type: SEND_INQUIRY_SUCCESS });
export const sendInquiryError = e => ({ type: SEND_INQUIRY_ERROR, error: true, payload: e });

export const fetchYearTimeSlotsRequest = () => ({
  type: FETCH_YEAR_TIME_SLOTS_REQUEST,
});
export const fetchYearTimeSlotsSuccess = data => ({
  type: FETCH_YEAR_TIME_SLOTS_SUCCESS,
  payload: data,
});
export const fetchYearTimeSlotsError = e => ({
  type: FETCH_YEAR_TIME_SLOTS_ERROR,
  error: true,
  payload: e,
});

export const fetchRegisteredPlayers = e => ({
  type: FETCH_REGISTERED_PLAYERS,
  payload: e,
})
// ================ Selectors ================ //

export const selectBookedSlots = state => {
  return {
    bookedSlots: state.ListingPage.bookedSlots,
    bookedTransactions: state.ListingPage.bookedTransactions,
  };
};

// ================ Thunks ================ //

export const showListing = (listingId, config, isOwn = false) => (dispatch, getState, sdk) => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images', 'currentStock'],
    'fields.image': [
      // Scaled variants for large images
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Cropped variants for listing thumbnail images
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      `variants.${variantPrefix}-4x`,
      `variants.${variantPrefix}-6x`,

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
  };

  const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

  return show
    .then(response => {
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };
      const listings = [response?.data?.data];

      // Mapping each listing to a fetchTimeSlots promise
      const promiseArray = listings.map(listing => {
        const timezone = listing?.attributes?.availabilityPlan?.timezone;
        const timeUnit = 'day';
        const currentDate = moment().toDate();
        const nextBoundary = findNextBoundary(currentDate, timeUnit, timezone);
        const nextBoundaryStartOfDay = moment(nextBoundary)
          .subtract(1, 'day')
          .startOf('day')
          .toDate();
        const nextMonth = getStartOf(nextBoundaryStartOfDay, 'month', timezone, 2, 'months');

        return dispatch(
          fetchTimeSlots(listing.id, nextBoundaryStartOfDay, nextMonth, timezone)
        ).then(res => {
          const firstTimeSlot = isArrayLength(res) && res[0];
          const nextEvent = firstTimeSlot?.attributes?.start
            ? moment(firstTimeSlot.attributes.start).format('DD.MM.YYYY; HH:mm')
            : null;

          const formattedSlotsToUnix = convertToUnixTimestamps(res);

          return {
            ...listing,
            attributes: {
              ...listing.attributes,
              nextEvent,
              nextEventUnix: isArrayLength(formattedSlotsToUnix) ? formattedSlotsToUnix[0] : null,
              publicData: {
                ...listing.attributes.publicData,
                eventDate: isArrayLength(formattedSlotsToUnix) ? formattedSlotsToUnix[0] : null,
                eventDates: formattedSlotsToUnix,
              },
            },
          };
        });
      });

      // Ensure all promises resolve and proceed with sorted listings
      return Promise.all(promiseArray).then(updatedListings => {
        const updatedResponse = {
          data: {
            data: isArrayLength(updatedListings) ? updatedListings[0] : {},
            included: response?.data?.included,
          },
        };

        dispatch(addMarketplaceEntities(updatedResponse, sanitizeConfig));
        return updatedResponse;
      });
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const fetchReviews = listingId => (dispatch, getState, sdk) => {
  dispatch(fetchReviewsRequest());
  return sdk.reviews
    .query({
      listing_id: listingId,
      state: 'public',
      include: ['author', 'author.profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(fetchReviewsSuccess(reviews));
    })
    .catch(e => {
      dispatch(fetchReviewsError(storableError(e)));
    });
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

export const fetchTimeSlots = (listingId, start, end, timeZone) => (dispatch, getState, sdk) => {
  const monthId = monthIdString(start, timeZone);

  const isAuthenticated = getState().auth.isAuthenticated;

  dispatch(fetchTimeSlotsRequest({ listingId, monthId }));

  // The maximum pagination page size for timeSlots is 500
  const extraParams = {
    perPage: 500,
    page: 1,
  };

  return dispatch(timeSlotsRequest({ listingId, start, end, ...extraParams }))
    .then(async timeSlots => {
      dispatch(fetchTimeSlotsSuccess(monthId, timeSlots));

      const { availabilityExceptions } =
        (await queryListingExceptions({
          listingId: listingId?.uuid,
          start,
          end,
        })) || {};

      // Filter availabilityExceptions to exclude matching entries from timeSlots
      const filteredAvailabilityExceptions = availabilityExceptions.filter(exception => {
        const exceptionStart = moment(exception.attributes.start).unix();
        const hasMatchingSlot = timeSlots.some(slot => {
          const slotStart = moment(slot.attributes.start).unix();
          return exceptionStart === slotStart;
        });
        return !hasMatchingSlot;
      });

      const ownTransactionsResponse =
        isAuthenticated &&
        (await sdk.transactions.query({
          only: 'order',
          listingId,
          include: ['booking', 'customer', 'provider'],
        }));

      const ownTransactions =
        isArrayLength(ownTransactionsResponse?.data?.data) && ownTransactionsResponse?.data?.data;

      if (ownTransactions) {
        dispatch(addMarketplaceEntities(ownTransactionsResponse));
        dispatch(fetchOrdersOrSalesSuccess(ownTransactionsResponse));
      }

      dispatch(
        updateTimeSlots({
          exceptions: filteredAvailabilityExceptions,
          bookedTransactions: ownTransactions,
        })
      );

      return timeSlots;
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(monthId, storableError(e)));
    });
};

export const sendInquiry = (currentUser, listing, message,intl) => (dispatch, getState, sdk) => {
  dispatch(sendInquiryRequest());
  const processAlias = listing?.attributes?.publicData?.transactionProcessAlias;
  const bookingDate = listing?.attributes?.publicData?.eventDates
  const allProtectedValues = {
    bookingEnd: Array.isArray(bookingDate) && bookingDate[bookingDate.length - 1],
    bookingStart: Array.isArray(bookingDate) && bookingDate[0],
    bookingStartUnix: moment(bookingDate[0]).unix(),
    bookingEndUnix: moment(bookingDate[bookingDate.length - 1]).unix()
  };
  if (!processAlias) {
    const error = new Error('No transaction process attached to listing');
    log.error(error, 'listing-process-missing', {
      listingId: listing?.id?.uuid,
    });
    dispatch(sendInquiryError(storableError(error)));
    return Promise.reject(error);
  }

  const listingId = listing?.id;
  const [processName, alias] = processAlias.split('/'); // eslint-disable-line no-unused-vars
  const transitions = getProcess(processName)?.transitions;

  const bodyParams = {
    transition: transitions.INQUIRE,
    processAlias,
    params: { listingId },
  };
  const type = "inquiry"
  const strapiTransactionParam = onGetTransactionParam(currentUser, listing, allProtectedValues, type,false,false,intl)
  const chatMessage = message ? onGetMessageParams(strapiTransactionParam, message) : null;
  return onCreateTransaction({ strapiTransactionParam, chatMessage })
    .then(response => {
      const transactionId = response.data.transactionId;
      // Send the message to the created transaction
      dispatch(sendInquirySuccess());
      dispatch(fetchCurrentUserHasOrdersSuccess(true));
      return transactionId;

    })
    .catch(e => {
      dispatch(sendInquiryError(storableError(e)));
      throw e;
    });
};

// This function will search for a timeSlot throughout the year
const lookForTimeSlots = async ({ dispatch, sdk, listing }) => {
  dispatch(fetchYearTimeSlotsRequest());

  const { attributes } = listing;
  const tz = attributes.availabilityPlan.timezone;
  const unitType = attributes.publicData?.unitType;
  const timeUnit = unitType === 'hour' ? 'hour' : 'day';
  let start = findNextBoundary(new Date(), timeUnit, tz);

  start = moment(start)
    .subtract(30, 'minutes')
    .toDate();

  let timeSlotsCount = 0;
  let monthlyTimeSlots = {};
  let allTimeSlots = [];
  let allTimeSlotsForThreeMonthsLoaded = false;

  // Iterate through 3 months
  for (let i = 0; i < 4; i++) {
    const end = getStartOf(start, 'month', tz, 1, 'month');

    const params = {
      listingId: listing.id,
      start,
      end,
      perPage: 500,
      page: 1,
    };
    const monthId = monthIdString(start, tz);
    monthlyTimeSlots[monthId] = {
      fetchTimeSlotsInProgress: true,
      fetchTimeSlotsError: null,
      timeSlots: [],
    };

    let filteredTimeSlots = []; // Define filteredTimeSlots outside the conditional

    const timeSlots = await sdk.timeslots
      .query(params)
      .then(response => {
        return denormalisedResponseEntities(response);
      })
      .catch(e => {
        monthlyTimeSlots[monthId] = {
          fetchTimeSlotsInProgress: false,
          fetchTimeSlotsError: e,
        };
      });

    if (timeSlots) {
      // Filter out time slots with less than 1 hour duration
      filteredTimeSlots = timeSlots.filter(slot => {
        const startTime = new Date(slot.attributes.start);
        const endTime = new Date(slot.attributes.end);
        return endTime - startTime >= 60 * 60 * 1000; // Check if duration is 1 hour or more
      });

      monthlyTimeSlots[monthId] = {
        fetchTimeSlotsError: null,
        fetchTimeSlotsInProgress: false,
        timeSlots: filteredTimeSlots,
      };
      allTimeSlots = allTimeSlots.concat(filteredTimeSlots);
    }

    timeSlotsCount += filteredTimeSlots.length; // Use filteredTimeSlots here
    start = new Date(end); // Update the start date to the end date for the next iteration
  }

  allTimeSlotsForThreeMonthsLoaded = true;
  dispatch(
    fetchYearTimeSlotsSuccess({ monthlyTimeSlots, allTimeSlots, allTimeSlotsForThreeMonthsLoaded })
  );
};

// Helper function for loadData call.
const fetchMonthlyTimeSlots = ({ dispatch, sdk, listing }) => {
  const hasWindow = typeof window !== 'undefined';
  const attributes = listing.attributes;
  // Listing could be ownListing entity too, so we just check if attributes key exists
  const hasTimeZone =
    attributes && attributes.availabilityPlan && attributes.availabilityPlan.timezone;

  // Fetch time-zones on client side only.
  if (hasWindow && listing.id && hasTimeZone) {
    lookForTimeSlots({ dispatch, sdk, listing });
  }

  // By default return an empty array
  return [];
};


export const onListingParticularTimeTransactions = ({ listingId, startDate }) => (dispatch, getState, sdk) => {

  return onGetListingTransactions({ listingId, startDate })
    .then((data) => {
      const players = data.particularDateTransactions
      const playersData = data.particularDateTransactionsData
      const playersRegister = data.particularDateTransactionsRegister
      dispatch(fetchParticularTransactionTimeBased(players, playersData, playersRegister))
    });
};



export const fetchTransactionLineItems = ({ orderData, listingId, isOwnListing }) => (dispatch, getState, sdk) => {
  dispatch(fetchLineItemsRequest());

  if (orderData && orderData.bookingStart) {
    dispatch(onListingParticularTimeTransactions({ listingId: listingId.uuid, startDate: utcFormatDateFromUnix(moment(orderData.bookingStart).unix()) }))
  }

  transactionLineItems({ orderData, listingId, isOwnListing })
    .then(response => {
      const lineItems = response.data;
      dispatch(fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        listingId: listingId.uuid,
        orderData,
      });
    });
};

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  const listingId = new UUID(params.id);

  // Clear old line-items
  dispatch(setInitialValues({ lineItems: null }));

  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  if (ownListingVariants.includes(params.variant)) {
    return dispatch(showListing(listingId, config, true));
  }

  return Promise.all([
    dispatch(showListing(listingId, config)),
    dispatch(fetchReviews(listingId)),
    dispatch(fetchCurrentUser())
  ]).then(response => {
    const listing = response[0].data.data;
    const transactionProcessAlias = listing?.attributes?.publicData?.transactionProcessAlias || '';
    if (isBookingProcessAlias(transactionProcessAlias)) {
      // Fetch timeSlots.
      // This can happen parallel to loadData.
      // We are not interested to return them from loadData call.
      fetchMonthlyTimeSlots({ dispatch, sdk, listing });
    }

    const currentUser = getState().user.currentUser;
    onGetListingTransactions({ listingId: listing.id.uuid, customerId: currentUser?.id?.uuid })
      .then((data) => {
        const { allTransactions, userTransactions, allRegisteredTransaction } = data;
        dispatch(fetchRegisteredPlayers({
          registeredPlayers: allTransactions,
          userTransactions,
          allRegisteredTransaction
        }))
      });


    return response;
  });
};
