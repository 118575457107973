import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { gameLabels } from '../../util/extraConfigOptions';
import { isBookingProcessAlias } from '../../transactions/transaction';

import {
  AspectRatioWrapper,
  AvatarSmall,
  NamedLink,
  PrimaryButton,
  ResponsiveImage,
  ReviewRating,
} from '../../components';

import css from './ListingCard.module.css';

import RenderMapListingCard from './RenderMapListingCard';
import RenderListingCard from './RenderListingCard';
import { listingFields } from '../../config/configListing';
import { games } from '../../config/games';
import { TOURNAMENT_DETAILS } from '../../util/enums';
import {
  findKeyBySubstring,
  getFormatKeyDetails,
  getLabelByFormat,
  getNextEventInfo,
  isArrayLength,
  safeFind,
} from '../../util/genericHelpers';
import { displayEventType, getDisplayName, getNextEventDate } from '../../util/dataExtractors';

export const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price?.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

export const LazyImage = lazyLoadWithDimensions(ResponsiveImage, {
  loadAfterInitialRendering: 3000,
});

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice = null, priceTitle = null } = price
    ? priceData(price, config.currency, intl)
    : {};
  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: null }} />
        </div>
      ) : null}
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    isMapListingModalCard,
  } = props;
  const classes = classNames(
    rootClassName || css.root,
    className,
    isMapListingModalCard ? css.mapListingModalCard : null
  );
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = getDisplayName(author);

  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  const gameIcon = gameLabels.find(e => e.key === publicData?.game);
  const validTradingCardId = [games[(gameIcon?.key)]];

  const listingFields = config.listing.listingFields;

  // Extracting games options
  const tournamentDetails = isArrayLength(listingFields)
    ? safeFind(listingFields, f => f?.key === TOURNAMENT_DETAILS)?.enumOptions
    : null;
  const formatDetails = isArrayLength(listingFields)
    ? safeFind(listingFields, f => f?.key === getFormatKeyDetails(publicData))?.enumOptions
    : null;

  const mapTournamentDetails =
    isArrayLength(tournamentDetails) &&
    tournamentDetails.map(t => {
      return {
        label: t.label,
        value: t.option,
        selected:
          isArrayLength(publicData?.tournamentdetails) &&
          publicData?.tournamentdetails.find(o => o === t.option),
      };
    });

  const { nextEventUnix = null } = getNextEventInfo(publicData?.eventDates) || {};
  const eventType = displayEventType(publicData?.frequency, publicData?.eventDates);

  const commonProps = {
    classes,
    id,
    slug,
    aspectWidth,
    aspectHeight,
    setActivePropsMaybe,
    title,
    firstImage,
    variants,
    renderSizes,
    gameIcon,
    config,
    eventType,
    nextEvent: nextEventUnix,
    format: getLabelByFormat(publicData, formatDetails),
    publicData,
    showAuthorInfo,
    author,
    authorName,
    mapTournamentDetails,
    bookingCalender:props?.bookingCalender?props?.bookingCalender:null,
    renderPriceMaybe: (
      <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />
    ),
  };

  return (
    <>
      {isMapListingModalCard ? (
        <RenderMapListingCard {...commonProps} />
      ) : (
        <RenderListingCard {...commonProps} />
      )}
    </>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
