import React, { useState, useEffect } from 'react';
import { func, number, object, string } from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import { FormattedMessage, intlShape } from '../../../util/reactIntl';
import {
  isInRange,
  timeOfDayFromLocalToTimeZone,
  isDateSameOrAfter,
  monthIdString,
  getStartOf,
} from '../../../util/dates';
import { propTypes } from '../../../util/types';
import { H6, IconArrowHead, IconSpinner, FieldTextInput } from '../../../components';

import {
  getAllTimeValues,
  getFirstAndSingleTimeSlot,
  getTimeSlotsSet,
  TIME_SLOTS_PER_SET,
} from './timeslotHelpers';

import css from './DateSelect.module.css';
import { isArrayLength } from '../../../util/genericHelpers';

const TODAY = new Date();

const nextMonthFn = (currentMoment, timeZone) =>
  getStartOf(currentMoment, 'month', timeZone, 1, 'months');
const prevMonthFn = (currentMoment, timeZone) =>
  getStartOf(currentMoment, 'month', timeZone, -1, 'months');

const endOfRange = (date, dayCountAvailableForBooking, timeZone) => {
  return getStartOf(date, 'day', timeZone, dayCountAvailableForBooking - 1, 'days');
};

const DateSelect = props => {
  const initialMonth = getStartOf(TODAY, 'month', props.timeZone);
  const [currentMonth, setCurrentMonth] = useState(initialMonth);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [currentTimeSlotIndex, setCurrentTimeSlotIndex] = useState(0);
  const {
    rootClassName,
    className,
    form,
    formId,
    monthlyTimeSlots,
    allTimeSlots,
    allTimeSlotsInProgress,
    allTimeSlotsError,
    allTimeSlotsWholeYearLoaded,
    timeZone,
    intl,
    onClearBookedValidation,
    showBookedDateValidation,
  } = props;

  const firstAndSingleTimeSlot = getFirstAndSingleTimeSlot(allTimeSlots);
  const selectedTimeSlots = getTimeSlotsSet({ allTimeSlots, index: currentTimeSlotIndex });

  const hideArrows =
    !!firstAndSingleTimeSlot ||
    (allTimeSlotsWholeYearLoaded && allTimeSlots.length <= TIME_SLOTS_PER_SET);
  const showLeftArrow = currentTimeSlotIndex !== 0;
  const showRightArrow =
    allTimeSlots.length !== 0 && currentTimeSlotIndex + TIME_SLOTS_PER_SET < allTimeSlots.length;

  useEffect(() => {
    if (firstAndSingleTimeSlot) {
      setSelectedTimeSlot(firstAndSingleTimeSlot);
    }
  }, [firstAndSingleTimeSlot]);

  useEffect(() => {
    if (selectedTimeSlot) {
      onTimeSlotChange(selectedTimeSlot);
    }
  }, [selectedTimeSlot]);

  const fetchMonthData = date => {
    const { listingId, timeZone, onFetchTimeSlots, dayCountAvailableForBooking } = props;
    const endOfRangeDate = endOfRange(TODAY, dayCountAvailableForBooking, timeZone);

    if (isInRange(date, TODAY, endOfRangeDate)) {
      const start = isDateSameOrAfter(TODAY, date) ? TODAY : date;
      const nextMonthDate = nextMonthFn(date, timeZone);
      const end = isDateSameOrAfter(nextMonthDate, endOfRangeDate)
        ? getStartOf(endOfRangeDate, 'day', timeZone)
        : nextMonthDate;

      onFetchTimeSlots(listingId, start, end, timeZone);
    }
  };

  useEffect(() => {
    const monthId = monthIdString(currentMonth, timeZone);
    const currentMonthData = monthlyTimeSlots[monthId];
    if (!currentMonthData) {
      fetchMonthData(currentMonth);
    }
  }, [currentMonth]);

  const onTimeSlotChange = timeSlot => {
    const startDate = timeOfDayFromLocalToTimeZone(timeSlot.attributes.start, timeZone);
    const timeSlotsOnSelectedDate = [timeSlot];

    const { startTime, endDate, endTime } = getAllTimeValues(
      intl,
      timeZone,
      timeSlotsOnSelectedDate,
      startDate
    );

    const selectedSlotIndex =
      isArrayLength(allTimeSlots) &&
      allTimeSlots.findIndex(t => t?.id?.uuid === timeSlot?.id?.uuid);

    const currentSlot = allTimeSlots[selectedSlotIndex]?.attributes?.start;
    const nextSlot = allTimeSlots[selectedSlotIndex + 1]?.attributes?.start;
    const calculatedNextSlot = nextSlot
      ? moment(nextSlot)
          .startOf('day')
          .toDate()
      : null;

    const isNextSlotSameDay = nextSlot && moment(currentSlot).isSame(moment(nextSlot), 'day');
    const endTimeOfNextSlot = isNextSlotSameDay ? calculatedNextSlot : timeSlot.attributes.end;

    form.batch(() => {
      form.change('bookingStartTime', startTime);
      form.change('bookingEndDate', { date: endTimeOfNextSlot });
      form.change('bookingEndTime', endTimeOfNextSlot.getTime());
    });
  };

  const classes = classNames(rootClassName || css.root, className);

  const onClickTimeSlot = timeSlot => e => {
    onClearBookedValidation();
    setSelectedTimeSlot(timeSlot);
  };

  const onClickPrev = e => {
    let index = currentTimeSlotIndex - TIME_SLOTS_PER_SET;
    if (index >= 0) {
      setCurrentTimeSlotIndex(index);
    }
  };

  const fetchNextMonth = () => {
    if (allTimeSlotsInProgress) {
      return;
    }

    const nextMonthDate = nextMonthFn(currentMonth, timeZone);

    if (moment(nextMonthDate).isAfter(moment(initialMonth).add(1, 'y'), 'year')) {
      return;
    }

    const monthId = monthIdString(nextMonthDate, timeZone);
    const monthData = monthlyTimeSlots[monthId];

    if (monthData) {
      if (monthData.fetchTimeSlotsInProgress) {
        return;
      }
      if (monthData.fetchTimeSlotsError) {
        fetchMonthData(nextMonthDate);
      }
    }

    setCurrentMonth(nextMonthDate);
  };

  const onClickNext = e => {
    if (showRightArrow) {
      let index = currentTimeSlotIndex + TIME_SLOTS_PER_SET;
      if (index < allTimeSlots.length) {
        const nextSet = getTimeSlotsSet({ allTimeSlots, index });
        if (nextSet.length < TIME_SLOTS_PER_SET) {
          fetchNextMonth();
        }
        setCurrentTimeSlotIndex(index);
      } else {
        fetchNextMonth();
      }
    }
  };

  return (
    <div className={classes}>
      <FieldTextInput
        type="hidden"
        name="bookingStartDate"
        id={formId ? `${formId}.bookingStartDate` : 'bookingStartDate'}
      />
      <FieldTextInput
        type="hidden"
        name="bookingStartTime"
        id={formId ? `${formId}.bookingStartTime` : 'bookingStartTime'}
      />
      <FieldTextInput
        type="hidden"
        name="bookingEndTime"
        id={formId ? `${formId}.bookingEndTime` : 'bookingEndTime'}
      />
      <div
        className={classNames(css.slotsBox, {
          [`${css.singleSlot}`]: hideArrows,
        })}
      >
        <div className={css.arrowLeft} onClick={onClickPrev}>
          {showLeftArrow && <IconArrowHead direction="left" rootClassName={css.arrow} />}
        </div>
        <div className={css.slotsList}>
          {allTimeSlotsInProgress && <IconSpinner />}
          {selectedTimeSlots.map((timeSlot, index) => {
            return (
              <div
                key={`time-slot-${index}`}
                className={classNames(css.timeSlot, {
                  [`${css.timeSlotHighLight}`]:
                    timeSlot.id.uuid === selectedTimeSlot?.id?.uuid && !showBookedDateValidation,
                })}
                onClick={onClickTimeSlot(timeSlot)}
              >
                {moment(timeSlot.attributes.start).format(
                  intl.formatMessage({ id: 'BookingTimeForm.dateFormat' })
                )}
                {intl.formatMessage({ id: 'BookingTimeForm.clock' })}
              </div>
            );
          })}
        </div>
        <div className={css.arrowRight} onClick={onClickNext}>
          {showRightArrow && <IconArrowHead direction="right" rootClassName={css.arrow} />}
        </div>
      </div>
    </div>
  );
};

DateSelect.defaultProps = {
  rootClassName: null,
  className: null,
  startDateInputProps: null,
  endDateInputProps: null,
  startTimeInputProps: null,
  endTimeInputProps: null,
  listingId: null,
  monthlyTimeSlots: null,
  timeZone: null,
};

DateSelect.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  startDateInputProps: object,
  endDateInputProps: object,
  startTimeInputProps: object,
  endTimeInputProps: object,
  form: object.isRequired,
  values: object.isRequired,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,
  timeZone: string,
  dayCountAvailableForBooking: number,

  intl: intlShape.isRequired,
};

export default DateSelect;
